import { useRecathModal } from "@/hooks/useRecathModal";
import { Box } from "@chakra-ui/react";
import {
  colors,
  HStack,
  GoiLogo,
  NextLink,
  customEvent,
  useResponsive,
  responsive,
  VStack,
  ColorIcon,
  Button,
  TabBar,
} from "goi_common";
import { useRouter } from "next/router";
import { useMemo } from "react";

export default function Header() {
  const { isBiggerThanLg } = useResponsive();
  const { openModal } = useRecathModal();

  return (
    <>
      <VStack
        css={responsive({
          height: {
            base: 96,
            lg: 66,
          },
          borderBottom: `1px solid ${colors.gray100}`,
          alignItems: "center",
          justifyContent: { base: "space-between", lg: "center" },
          padding: { base: "10px 10px 0", lg: "0" },
          margin: "0 auto",
          position: "fixed",
          width: "100%",
          top: "0",
          backgroundColor: colors.white,
          transition: "background-color 0.1s linear",
          zIndex: "1000",
          userSelect: "none",
        })}
      >
        <HStack
          alignItems="center"
          justifyContent="space-between"
          gap={isBiggerThanLg ? "36px" : "12px"}
          maxWidth="1200px"
          width={"100%"}
        >
          <HStack gap="12px" alignItems="center">
            <NextLink
              id="goi-logo"
              href={"/"}
              css={{ width: "fit-content" }}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "logo-uv_home",
                  },
                });
              }}
            >
              <HStack
                gap={isBiggerThanLg ? "12px" : "8px"}
                margin={isBiggerThanLg ? 0 : "0 14px"}
                alignItems="center"
              >
                <GoiLogo color={"black"} size={isBiggerThanLg ? 30 : 24} />
                <div className={"subtitle_18_b md_subtitle_21_b black"}>
                  BIZ
                </div>
              </HStack>
            </NextLink>

            <HeaderTabBar className="lg_under_hidden" />
          </HStack>
          <Button
            css={responsive({
              borderRadius: "30px",
              width: "fit-content",
              padding: "8px 12px",
              backgroundColor: colors.orange650,
              color: colors.white,
            })}
          >
            <HStack gap="3px" alignItems="center" onClick={openModal}>
              <Box lineHeight="10px" fontSize={14} fontWeight={600}>
                도입 문의
              </Box>
              <ColorIcon name="headphone-mono" size={16} color={colors.white} />
            </HStack>
          </Button>
        </HStack>
        <HeaderTabBar className="lg_over_hidden" />
      </VStack>
    </>
  );
}

function HeaderTabBar({ className }: { className: string }) {
  const { activePath } = useActivePathCheck();
  const { isBiggerThanLg } = useResponsive();

  const menu: Array<{
    text: string;
    to: string;
    eventDetails: any;
    onClick?: () => void;
  }> = [
    {
      text: "장례서비스",
      to: "/funeral/",
      eventDetails: ["uv_funeral"],
    },
    {
      text: "상조용품",
      to: "/disposal/",
      eventDetails: ["uv_disposal"],
    },
    {
      text: "근조화환",
      to: "/flower/",
      eventDetails: ["uv_flower"],
    },
  ];

  return (
    <HStack
      css={responsive({
        height: { base: "39px", lg: "100%" },
        marginLeft: { base: "0", lg: "40px" },
      })}
      width="100%"
      justifyContent="space-between"
      gap={isBiggerThanLg ? "50px" : "0"}
      className={className}
    >
      {menu.map((item, i) => {
        return (
          <Box position="relative" key={i}>
            <NextLink href={item.to}>
              <TabBar.Tab
                className={"subtitle_18_sb gray500"}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  item.eventDetails.map((trigger_and_target: any) => {
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target,
                      },
                    });
                  });
                }}
                isActive={activePath === item.text}
                activeFontStyle={{
                  color: colors.gray900,
                }}
                activeBarStyle={{
                  borderBottom: isBiggerThanLg ? "none" : "3px solid black",
                }}
                css={responsive({
                  height: { base: "40px", lg: "fit-content" },
                })}
              >
                {item.text}
              </TabBar.Tab>
            </NextLink>
          </Box>
        );
      })}
    </HStack>
  );
}

export function useActivePathCheck() {
  const router = useRouter();

  const activePath = useMemo(() => {
    const path = router.asPath;
    if (path.startsWith("/funeral/")) return "장례서비스";
    else if (path.startsWith("/disposal/")) return "상조용품";
    else if (path.startsWith("/flower/")) return "근조화환";
    return "";
  }, [router]);

  return { activePath };
}
